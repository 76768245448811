import React from "react";
import { Slider } from "antd";

const Sliderr = ({ onChange }: any) => {
  return (
    <div>
      <Slider
        onChange={(e) => {
          onChange(e);
        }}
        range
        defaultValue={[0, 10000]}
      />
    </div>
  );
};

export default Sliderr;
