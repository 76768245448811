import React from "react";
import { Checkbox } from "antd";

const App: React.FC<{
  data: {
    label: string;
    bgColor?: string;
    color?: string;
  };
  onChange: (label: string, checked: boolean) => void;
}> = ({ data, onChange }) => {
  return (
    <Checkbox
      onChange={(e) => {
        onChange(data.label, e.target.checked);
      }}
    >
      <span
        style={{
          backgroundColor: data.bgColor,
          color: data.color,
          ...(data.bgColor && {
            padding: "5px",
            borderRadius: "12px",
          }),
        }}
        className="text-[#344054] text-[14px] font-medium capitalize"
      >
        {data?.label}
      </span>
    </Checkbox>
  );
};

export default App;
