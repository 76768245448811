import { Typography } from "antd";
import React from "react";

export const StatusBtn = ({ item }) => {
  const { Text } = Typography;

  return (
    <Text
      style={
        item === "Unpaid" || item === "Declined"
          ? {
              backgroundColor: "#FFFAEB",
              color: "#B54708",
            }
          : {
              backgroundColor: "#ECFDF3",
              color: "#027A48",
            }
      }
      className="text-[14px] font-normal w-auto rounded-[16px] p-1 px-3"
    >
      {item}
    </Text>
  );
};
