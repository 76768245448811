import React from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { DataType, TableRowSelection } from "../types/table.ts";
import { mapDataToTableFormat } from "./map-data-to-table-format.tsx";
import TableContent from "./table-content.tsx";

const App: React.FC = ({
  setIsViewOpen,
  activeTab,
  setIsEditOpen,
  column,
  data,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedObject,
  setPayoutData,
  pyoutData,
  isShowRow,
  isCompany,
}: any) => {
  const onSelectChange = (newSelectedRowKeys: React.Key[]) =>
    setSelectedRowKeys(newSelectedRowKeys);

  window.localStorage.setItem("payout_counts", pyoutData?.length);

  if (activeTab === 0) {
    const statusColumnIndex = column.findIndex(
      (column) => column.key === "column5"
    );

    if (statusColumnIndex !== -1) {
      const filtered = column.filter((x) => x.key !== "column5");

      column = filtered;
    }
  }

  const tabledata: DataType[] = mapDataToTableFormat(
    data,
    activeTab,
    isCompany,
    setIsEditOpen,
    setIsViewOpen,
    setSelectedObject
  );

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys: any = [];

          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });

          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys: any = [];

          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });

          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleApprove = () => {
    const updatedData = tabledata.filter(
      (item) => !selectedRowKeys.includes(item.key)
    );

    setPayoutData(updatedData);

    setSelectedRowKeys([]);

    toast.success("Approved");
  };

  return (
    <TableContent
      column={column}
      handleApprove={handleApprove}
      isShowRow={isShowRow}
      rowSelection={rowSelection}
      selectedRowKeys={selectedRowKeys}
      tabledata={tabledata}
    />
  );
};

export default App;
