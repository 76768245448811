import { Button, Table, Typography } from "antd";
import React from "react";

const TableContent = ({
  selectedRowKeys,
  handleApprove,
  isShowRow,
  rowSelection,
  column,
  tabledata,
}: {
  selectedRowKeys: any;
  handleApprove: any;
  isShowRow: any;
  rowSelection: any;
  column: any;
  tabledata: any;
}) => {
  const { Text } = Typography;
  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
      }}
      className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
    >
      {selectedRowKeys?.length > 0 && (
        <div className="w-full bg-white h-20 items-center  flex justify-end gap-4 px-4">
          <Button
            onClick={handleApprove}
            type="primary"
            className=" h-[44px] flex items-center bg-[#5A59A1] rounded-[8px] font-semibold text-[14px] text-[#fff]"
          >
            Approve Selected
          </Button>

          <Button
            type="text"
            className=" h-[44px] flex items-center bg-[#D92D20] rounded-[8px] font-semibold text-[14px] text-[#fff]"
          >
            Decline & Remove
          </Button>
        </div>
      )}

      <Table
        rowSelection={isShowRow ? undefined : rowSelection}
        columns={column}
        dataSource={tabledata}
        style={{
          borderTop: "1px solid #EAECF0",
        }}
        pagination={false}
      />

      <div className="w-full bg-white h-20 items-center  px-4  flex justify-between gap-4">
        <Text className="text-[#344054] text-[14px] font-medium ">
          Page 1 of 1
        </Text>

        <div className="flex items-center gap-3">
          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
          >
            Previous
          </button>

          <button
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableContent;
