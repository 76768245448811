import React, { HTMLProps } from "react";
import { Form, Input, Typography } from "antd";

interface inputProps extends HTMLProps<HTMLInputElement> {
  required?: boolean;
  isErr?: any;
}

const InputField = ({
  name,
  required,
  isErr,
  size,
  ref,
  label,
  ...rest
}: inputProps): JSX.Element => {
  const { Title } = Typography;

  return (
    <>
      <Title
        level={5}
        style={{
          color: "#344054",
        }}
      >
        {label}
      </Title>

      <Form.Item
        name={name}
        rules={[
          {
            required: required,
          },
        ]}
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
      >
        <Input
          style={{
            border: isErr ? "1.5px solid #FF4D4F" : "1.5px solid #D0D5DD",
            height: "44px",
          }}
          className="placeholder:text-[#667085]  bg-transparent placeholder:text-[16px] rounded-[8px]"
          {...rest}
          name={name}
          value={rest.value}
        />

        {isErr && <p className="text-[#FF4D4F]">{isErr}</p>}
      </Form.Item>
    </>
  );
};

export default InputField;
