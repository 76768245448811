import React from "react";

export const ButtonComponent = ({
  item,
  isCompany,
  activeTab,
  setIsEditOpen,
  setSelectedObject,
  setIsViewOpen,
}: {
  item: any;
  isCompany: any;
  activeTab: any;
  setIsEditOpen: any;
  setSelectedObject: any;
  setIsViewOpen: any;
}) => {
  return (
    <div className="flex items-center gap-4 justify-end ">
      {!isCompany && (
        <>
          <button
            disabled={
              activeTab === 1
                ? item.Status === "Paid Automated" ||
                  item.Status === "Paid Manual"
                : false
            }
            onClick={() => {
              setIsEditOpen(true);
              setSelectedObject(item);
            }}
            className={`font-semibold text-[#475467] hover:text-[#5A59A1]  ${
              activeTab === 1
                ? (item.Status === "Paid Automated" ||
                    item.Status === "Paid Manual") &&
                  "text-[#D0D5DD] hover:text-[#D0D5DD]"
                : ""
            } `}
          >
            Edit
          </button>
        </>
      )}

      <button
        onClick={() => {
          setIsViewOpen(true);
        }}
        className="font-semibold text-[#475467] hover:text-[#5A59A1]"
      >
        View
      </button>
    </div>
  );
};
