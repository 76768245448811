import React from "react";
import { StatusBtn } from "./status-button.tsx";
import { ButtonComponent } from "./button-new.tsx";

export const mapDataToTableFormat = (
  data: any[],
  activeTab: any,
  isCompany: any,
  setIsEditOpen: any,
  setIsViewOpen: any,
  setSelectedObject: any
) => {
  return data?.map((item, index) => ({
    ...item,
    key: index,
    name: item.name,
    Type: item.type || item.Type,
    Amount: item.amount || item.Amount,
    Company: item.company || item.Company,
    Date: item.dates || item.Date,
    Status: activeTab === 1 ? <StatusBtn item={item.Status} /> : null,
    edit: (
      <ButtonComponent
        activeTab={activeTab}
        isCompany={isCompany}
        setIsEditOpen={setIsEditOpen}
        setIsViewOpen={setIsViewOpen}
        setSelectedObject={setSelectedObject}
        item={item}
        key={index}
      />
    ),
  }));
};
