import React from "react";
import { Button } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

type inputProps = {
  size?: SizeType;
  type?: "link" | "text" | "default" | "primary" | "dashed" | undefined;
  shape: "default" | "circle" | "round" | undefined;
  icon: string;
  label?: string;
  background?: string;
  color?: string;
};

const button = ({
  size,
  type,
  shape,
  icon,
  label,
  background,
  color,
}: inputProps): JSX.Element => {
  return (
    <Button
      type={type}
      size={size}
      shape={shape}
      icon={icon}
      style={{
        ...(background && {
          backgroundColor: background,
        }),
        ...(color && {
          color: color,
        }),
      }}
      className="w-full h-[44px] bg-[#5A59A1] rounded-[8px] font-semibold text-[16px] text-[#fff]"
    >
      {label}
    </Button>
  );
};

export default button;
